import React, { useImperativeHandle, useEffect } from "react"
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {financeActions, financeSelectors, financeOperations} from "../../state/finance"
import ProductSelectableAccessories from "./ProductSelectableAccessories"
import {dataLayerUpdate} from "../../../src/api/dataLayer/gaDataLayer";

const ProductSelectableAccessoriesContainer = React.forwardRef(({addAccessory, removeAccessory, product, removeAllAccessories, fetchAccessories, productAccessories, isFetchingAccessories, selectedAccessories, queryTermInMonths, location}, ref) => {

  useImperativeHandle(ref, () => ({
    removeAccessories: () => {
      removeAllAccessories()
    }
  }));

  useEffect(()=> {
    fetchAccessories()
  }, [])

  const handleAddAccessory = (product,variant) => {
    addAccessory(product,variant)
    dataLayerUpdate('finance_add_accessory', product)
  }
  const handleRemoveAccessory = product => {
    removeAccessory(product)
    dataLayerUpdate('finance_remove_accessory', product)
  }

  return (
    <ProductSelectableAccessories
      location={location}
      products={productAccessories}
      isLoading={isFetchingAccessories}
      onAddAccessory={handleAddAccessory}
      onRemoveAccessory={handleRemoveAccessory}
      selectedAccessories={selectedAccessories}
      queryTermInMonths={queryTermInMonths}
    />
  );
})

const mapStateToProps = state => ({
  product: financeSelectors.getProduct(state),
  selectedAccessories: financeSelectors.getAccessories(state),
  isFetchingAccessories: financeSelectors.isFetchingAccessories(state),
  productAccessories: financeSelectors.getProductAccessories(state),
  queryTermInMonths: financeSelectors.getQueryTermInMonths(state)
});

const mapDispatchToProps = {
  addAccessory: financeActions.addAccessory,
  removeAccessory: financeActions.removeAccessory,
  removeAllAccessories: financeActions.removeAllAccessories,
  fetchAccessories: financeOperations.fetchAccessories,
};

ProductSelectableAccessoriesContainer.propTypes = {

};

export default connect(mapStateToProps, mapDispatchToProps, null,{ forwardRef: true }) (ProductSelectableAccessoriesContainer);
