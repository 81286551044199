import React, {useRef} from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Layout from "../components/Layout"
import CheckoutStepper, { CheckoutStep } from "../components/CheckoutStepper"
import ProductSelectableAccessories from "../components/ProductSelectableAccessories/ProductSelectableAccessoriesContainer"
import { Link, navigate } from "gatsby"
import {financeSelectors} from "../state/finance";
import {connect} from "react-redux";
import {dataLayerUpdate} from "../../src/api/dataLayer/gaDataLayer";

const CheckoutFinanceAccessoriesPage = ({ location, product }) => {
  dataLayerUpdate('finance_accessories', product)
  const productSelectableAccessoriesRef = useRef(null)

  const handleRemoveAccessories = () => {
    productSelectableAccessoriesRef.current.removeAccessories()

    navigate("/finance-checkout-data")
  }

  return (
    <Layout
      staticSeo={{ seoTitle: "Checkout finance", seoDescription: "Checkout finance process" }}
      location={{ pathname: location.pathname }}
    >
      <CheckoutStepper activeStepNumber={2}>
        <CheckoutStep pageLink={"/finance-checkout-product"} title="Choose a bike"/>
        <CheckoutStep pageLink={location.pathname} title="Choose accessories"/>
        <CheckoutStep pageLink={"/finance-checkout-data"} title="Choose delivery method"/>
        <CheckoutStep pageLink={"/finance-checkout-summary"} title="Finish"/>
      </CheckoutStepper>

      <Container className="my-5">
        <Row>
          <Col xs={12}>
            <h3 className="pb-4">Do you want to add accessories to your financing application</h3>
            <p className="m-0">
              You can add the accessories of your choice from the list below to your funding application.
              Depending on the added elements, an additional calculation of financing costs will be prepared,
              which you will receive in the update by e-mail
            </p>
            <Button onClick={handleRemoveAccessories} id="continueWithoutAccessoriesMobile" className="w-100 d-block d-md-none mt-5">I don't want to add anything</Button>
            <hr className="my-3 my-lg-5" />

          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ProductSelectableAccessories ref={productSelectableAccessoriesRef} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} >
            <hr className="my-3 my-lg-5" />
          </Col>
          <Col xs={12} md={6}>
            <Button onClick={handleRemoveAccessories} id="continueWithoutAccessoriesDesktop" className="w-100 d-none d-md-block">I don't want to add anything</Button>
          </Col>
          <Col xs={12} md={6}>
            <Button as={Link} to="/finance-checkout-data" id="continueWithChosenAccessories" className="w-100">Add choosen accessories</Button>
          </Col>
        </Row>
      </Container>

    </Layout>
  )
}

const mapStateToProps = state => ({
  product: financeSelectors.getProduct(state),
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (CheckoutFinanceAccessoriesPage)
