import React, { Component, useEffect, useState } from "react"
import * as PropTypes from "prop-types"
import * as styles from "./ProductSelectableAccessories.module.scss"
import ProductSelectableAccessoryCard from "./ProductSelectableAccessoryCard"


const ProductSelectableAccessories = ({ products, isLoading,onAddAccessory, onRemoveAccessory, selectedAccessories, queryTermInMonths, location }) => {

  const handleAddAccessory = (product,variant) => {
    onAddAccessory && onAddAccessory(product,variant)
  }

  const handleRemoveAccessory = (product) => {
    onRemoveAccessory && onRemoveAccessory(product)
  }

  if(isLoading){
    return (
      <div>
        Loading data...
      </div>
    )
  }

  if(!products?.length){
    return (
      <div>
        No accessories available
      </div>
    )
  }

    return (
      <div>
        {products.map(product => {
          const selectedItem = selectedAccessories.find(item=> item.product.id === product.id) || null
          const selected = selectedItem !== null
          const selectedVariant = selectedItem !== null ? selectedItem.variant : null
          return (
          <div key={product.id} className="mt-3">
            <ProductSelectableAccessoryCard location={location} product={product} selected={selected} selectedVariant={selectedVariant} onAdd={handleAddAccessory} onRemove={handleRemoveAccessory} queryTermInMonths={queryTermInMonths}/>
          </div>
        )})}
      </div>
    )
}

ProductSelectableAccessories.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onAddAccessory: PropTypes.func,
  onRemoveAccessory: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })),
  selectedAccessories: PropTypes.arrayOf(PropTypes.shape({
    product: PropTypes.object.isRequired,
    variant: PropTypes.object,
  })),
  queryTermInMonths: PropTypes.number.isRequired
}

export default ProductSelectableAccessories
