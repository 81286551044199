import React from "react"
import * as PropTypes from "prop-types"
import * as styles from "./ProductSelectableAccessoryCard.module.scss"
import { Form, Button } from "react-bootstrap"
import { ModalPortal, ToggleModal } from "../../portals/Modal.portal"
import {getMinFinancingAccessoryValue, getMaxFinancingAccessoryValue} from "../../utils/ivendi"


const ProductSelectableAccessoryCard = ({product, onAdd, onRemove, selected, selectedVariant,queryTermInMonths, location}) => {
  const handleAddButtonCLick = () => {
    if(selected) {
      onRemove && onRemove(product)
    } else {
      onAdd && onAdd(product, null)
    }

  }

  const handleSelectVariant = (variant) => {
    onAdd && onAdd(product, variant)
  }

  const handleUnselectVariant = () => {
    onRemove && onRemove(product)
  }

  const getAttributeByName = ( variant, attributeName ) => {
    return variant.attributes.find(attribute => attribute.name === attributeName)
  }

  const formId = "SELECT_ACCESSORIES_ACCESSORY_CARD"
  const formType = "SELECT_ACCESSORIES"

  const selectButton = product.type === 'variable' ? (
    <ToggleModal
      formId={formId}
      formType={formType}
      location={location}
      toggle={show => {
        return (
          <Button onClick={show}>Select</Button>
        )
      }}
      content={hide => {
        return (
          <ModalPortal
            heading="Select variant"
            hide={hide}
            size="lg"
            centered={true}>
            <div>
              {product.variants.map(variant => {

                const attributeColor = getAttributeByName(variant,'Colour')
                const attributeSize = getAttributeByName(variant, 'Size')

                return (
                  <div key={variant.id} className={styles.variantCard}>
                    <Form.Check type="checkbox" checked={selected && selectedVariant && selectedVariant.id === variant.id} readOnly={true} />
                    <img src={variant.imageSrc} alt="Product image"  width={90} height={90} />
                    <p >
                      <span>Full Price</span>
                      <br/>
                      <br/>
                      <span>{`${process.env.CURRENCY_SYMBOL}${variant.price}`}</span>
                    </p>
                    {!!attributeColor && (
                      <p >
                        <span>Colour</span>
                        <br/>
                        <br/>
                        <span>{attributeColor.value}</span>
                      </p>
                    )}
                    {!!attributeSize && (
                      <p >
                        <span>Size</span>
                        <br/>
                        <br/>
                        <span>{attributeSize.value}</span>
                      </p>
                    )}
                    {selected && selectedVariant && selectedVariant.id === variant.id ? (
                      <Button onClick={()=> {hide(); handleUnselectVariant()}} className={styles.variantButton}>Unselect</Button>
                    ) : (
                      <Button onClick={()=> {hide();handleSelectVariant(variant)}} className={styles.variantButton}>Select</Button>
                    )}
                  </div>
                )})}
            </div>
          </ModalPortal>
        )}}
    />
  ) : (
    <Button onClick={handleAddButtonCLick}>{selected ? "Remove": "Select"}</Button>
  )

  const priceElement = selected && !!selectedVariant ? (
    <span>{`${process.env.CURRENCY_SYMBOL}${selectedVariant.price}`}</span>
  ) : (
    <span>{`${process.env.CURRENCY_SYMBOL}${product.price}`}</span>
  )

  const productImage = selected && !!selectedVariant ? (
    <img src={selectedVariant.imageSrc} alt={product.imageAlt} width={90} height={90} />
  ) : (
    <img src={product.imageSrc} alt={product.imageAlt} width={90} height={90} />
  )

  const minRequestValue = getMinFinancingAccessoryValue(product.price,queryTermInMonths);
  const maxRequestValue = getMaxFinancingAccessoryValue(product.price,queryTermInMonths);

  const increasedByText = `${process.env.CURRENCY_SYMBOL}${minRequestValue} - ${process.env.CURRENCY_SYMBOL}${maxRequestValue}`

  return (
      <>
        <div className={styles.card}>
          <Form.Check type="checkbox" checked={selected} readOnly={true} />
          {productImage}
          <div className={styles.colName}>
            <p className={styles.colHeader}>Product</p>
            <span>{product.name}</span>
          </div>
          <div className={styles.colPrice}>
            <p className={styles.colHeader}>Full Price</p>
            {priceElement}
          </div>
          <div className={styles.colIncreased}>
            <p className={styles.colHeader}>Increased of the monthly payment by</p>
            <span>{increasedByText}</span>
          </div>
          {selectButton}
        </div>
        <div className={styles.mobileCard}>
          <div className="d-flex flex-row align-items-center">
            {productImage}
            <p className={styles.mobileColName}>
              <span>Product</span>
              <br/>
              <br/>
              <span className="mt-2">{product.name}</span>
            </p>
          </div>
          <hr className="my-2 w-100"/>
          <div className="d-flex flex-row">
            <div className={styles.mobileColPrice}>
              <p className={styles.mobileColHeader}>Full Price</p>
              {priceElement}
            </div>
            <div className={styles.mobileColIncreased}>
              <p className={styles.mobileColHeader}>Increased of the monthly payment by</p>
              <span>{increasedByText}</span>
            </div>
          </div>
          <hr className="my-2 w-100"/>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <Form.Check type="checkbox" checked={selected} readOnly={true} />
            {selectButton}
          </div>
        </div>
      </>
    )
}

ProductSelectableAccessoryCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    stockStatus: PropTypes.string,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    variants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      imageSrc: PropTypes.string,
      stockStatus: PropTypes.string.isRequired,
      attributes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }))
    }))
  }),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  queryTermInMonths: PropTypes.number.isRequired
}

ProductSelectableAccessoryCard.defaultProps = {
  className: "",
}

export default ProductSelectableAccessoryCard
