// extracted by mini-css-extract-plugin
export var bgGrey100 = "ProductSelectableAccessoryCard-module--bg-grey-100--b3152";
export var bgGrey150 = "ProductSelectableAccessoryCard-module--bg-grey-150--870e9";
export var bgGrey200 = "ProductSelectableAccessoryCard-module--bg-grey-200--3905e";
export var bgGrey300 = "ProductSelectableAccessoryCard-module--bg-grey-300--2d465";
export var bgGrey400 = "ProductSelectableAccessoryCard-module--bg-grey-400--69a1f";
export var bgGrey500 = "ProductSelectableAccessoryCard-module--bg-grey-500--229b2";
export var bgGrey600 = "ProductSelectableAccessoryCard-module--bg-grey-600--c00e5";
export var bgGrey700 = "ProductSelectableAccessoryCard-module--bg-grey-700--a4450";
export var bgGrey800 = "ProductSelectableAccessoryCard-module--bg-grey-800--6364b";
export var bgGrey900 = "ProductSelectableAccessoryCard-module--bg-grey-900--84347";
export var card = "ProductSelectableAccessoryCard-module--card--266b0";
export var colHeader = "ProductSelectableAccessoryCard-module--colHeader--ae093";
export var colIncreased = "ProductSelectableAccessoryCard-module--colIncreased--fbdcd";
export var colName = "ProductSelectableAccessoryCard-module--colName--fd991";
export var colPrice = "ProductSelectableAccessoryCard-module--colPrice--6ddf4";
export var mobileCard = "ProductSelectableAccessoryCard-module--mobileCard--c343e";
export var mobileColHeader = "ProductSelectableAccessoryCard-module--mobileColHeader--1ba8f";
export var mobileColIncreased = "ProductSelectableAccessoryCard-module--mobileColIncreased--ac101";
export var mobileColName = "ProductSelectableAccessoryCard-module--mobileColName--6a0a8";
export var mobileColPrice = "ProductSelectableAccessoryCard-module--mobileColPrice--46c72";
export var textGrey100 = "ProductSelectableAccessoryCard-module--text-grey-100--8bf03";
export var textGrey150 = "ProductSelectableAccessoryCard-module--text-grey-150--79ee8";
export var textGrey200 = "ProductSelectableAccessoryCard-module--text-grey-200--2624b";
export var textGrey300 = "ProductSelectableAccessoryCard-module--text-grey-300--1b29d";
export var textGrey400 = "ProductSelectableAccessoryCard-module--text-grey-400--df62e";
export var textGrey500 = "ProductSelectableAccessoryCard-module--text-grey-500--773ce";
export var textGrey600 = "ProductSelectableAccessoryCard-module--text-grey-600--6b1e2";
export var textGrey700 = "ProductSelectableAccessoryCard-module--text-grey-700--47ee7";
export var textGrey800 = "ProductSelectableAccessoryCard-module--text-grey-800--939c0";
export var textGrey900 = "ProductSelectableAccessoryCard-module--text-grey-900--dad7d";
export var variantButton = "ProductSelectableAccessoryCard-module--variantButton--0b568";
export var variantCard = "ProductSelectableAccessoryCard-module--variantCard--32b96";